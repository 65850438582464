module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Luis Cestou","short_name":"Luis Cestou","start_url":"/","background_color":"#ffffff","theme_color":"#f1f2ff","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"36d421e0be37c51ce792888407f48297"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-62005347-1"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.3_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-transition-link@1.20.5_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.6.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
